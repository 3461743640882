.background-image {
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  border-radius: 0px;
  border: 0px;
  background-image: url("../public/background.png");
  background-position: -2.5vw;
  background-size: cover;
}
